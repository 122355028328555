import * as React from "react";
import { CheckIcon } from "@heroicons/react/20/solid";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export enum StepperStatus {
  Current = "current",
  Upcoming = "upcoming",
  Complete = "complete",
}

export interface StepperProps {
  steps: {
    name: string;
    description: string;
    status: StepperStatus;
  }[];
  onStepClick: (index: number) => void;
}

const Stepper = ({ steps, onStepClick }: StepperProps) => {
  return (
    <nav aria-label="Progress">
      <ol role="list" className="overflow-hidden">
        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={classNames(
              stepIdx !== steps.length - 1 ? "pb-10" : "",
              "relative"
            )}
          >
            {step.status === StepperStatus.Complete ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-green-600"
                    aria-hidden="true"
                  />
                ) : null}
                <button
                  onClick={() => onStepClick(stepIdx)}
                  className="group relative flex items-start"
                >
                  <span className="flex h-9 items-center">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-green-600 group-hover:bg-green-700">
                      <CheckIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col items-start text-start">
                    <span className="text-lg font-medium text-black/80">
                      {step.name}
                    </span>
                    <span className="text-sm text-black/50">
                      {step.description}
                    </span>
                  </span>
                </button>
              </>
            ) : step.status === StepperStatus.Current ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                    aria-hidden="true"
                  />
                ) : null}
                <button
                  onClick={() => onStepClick(stepIdx)}
                  className="group relative flex items-start"
                  aria-current="step"
                >
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-900 bg-white">
                      <span className="h-2.5 w-2.5 rounded-full bg-indigo-900" />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col items-start text-start">
                    <span className="text-lg font-semibold text-indigo-900">
                      {step.name}
                    </span>
                    <span className="text-md text-black/70">
                      {step.description}
                    </span>
                  </span>
                </button>
              </>
            ) : (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                    aria-hidden="true"
                  />
                ) : null}
                <button
                  onClick={() => onStepClick(stepIdx)}
                  className="group relative flex items-start"
                >
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-indigo-900">
                      <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-indigo-900" />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col items-start text-start">
                    <span className="text-lg font-medium text-black/70">
                      {step.name}
                    </span>
                    <span className="text-sm text-black/50">
                      {step.description}
                    </span>
                  </span>
                </button>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Stepper;
