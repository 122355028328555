import React from "react";
import { useLocation } from "@reach/router";

export const useRedirectUrl = (): [string, string] => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const url = params.get("redirectUrl");
  const search = params.get("redirectSearch");

  return [url ?? "", search ?? ""];
};
