import * as React from "react";
import { HeadFC, Link, navigate } from "gatsby";
import { CustomHead } from "../../components/shell/CustomHead";
import Shell from "../../components/shell/Shell";
import CreateAccountForm from "../../components/forms/CreateAccountForm";
import Stepper, { StepperStatus } from "../../components/shared/Stepper";
import { Tab } from "@headlessui/react";
import CreateProfileForm from "../../components/forms/CreateProfileForm";
import { defaultLanguage } from "../../core/constants";
import { defaultRelationshipStatus } from "../../components/fields/RelationshipStatusField";
import { defaultSex } from "../../components/fields/SexField";
import EmailForm from "../../components/forms/EmailForm";
import { useAuthUser, useFirebaseService } from "../../core/contexts/firebase";
import { Id, toast } from "react-toastify";
import { TypeOptions } from "react-toastify/dist/types";
import { FirebaseError } from "firebase/app";
import { FirebaseAuthErrorCode } from "../../core/services/firebase";
import { UserCredential } from "firebase/auth";
import { useRedirectUrl } from "../../core/hooks/useRedirectUrl";

const pageDescription =
  "Sign up to access all our features meant to make your financing experience with us that much better.";
const defaultSteps = [
  {
    name: "1. Search for your account",
    description:
      "We might already know you. Skip account creation if you already have an existing account.",
    status: StepperStatus.Current,
  },
  {
    name: "2. Profile information",
    description:
      "Provide a few more details to tell us a little more about yourself.",
    status: StepperStatus.Upcoming,
  },
  {
    name: "3. Create account",
    description: "Enter your account details and access the dashboard.",
    status: StepperStatus.Upcoming,
  },
  // {
  //   name: "4. Verify email",
  //   description: "Enter the provided OTP to verify your email address.",
  //   status: StepperStatus.Upcoming,
  // },
  // {
  //   name: "5. Verify phone",
  //   description: "Enter the provided OTP to verify your phone number.",
  //   status: StepperStatus.Upcoming,
  // },
];

const SignUpPage = ({ location }: any) => {
  const authUser = useAuthUser();
  const firebaseService = useFirebaseService();
  const [selectedIndex, setSelectedIndex] = React.useState(
    location.state?.skipSearch ? 1 : 0
  );
  const [redirectUrl, redirectSearch] = useRedirectUrl();
  const [steps, setSteps] = React.useState(defaultSteps);
  const [email, setEmail] = React.useState(location.state?.email ?? "");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [idNumber, setIdNumber] = React.useState("");
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] =
    React.useState("");
  const [selectedLanguage, setSelectedLanguage] =
    React.useState(defaultLanguage);
  const [selectedSex, setSelectedSex] = React.useState(defaultSex);
  const [selectedRelationshipStatus, setSelectedRelationshipStatus] =
    React.useState(defaultRelationshipStatus);

  const getRedirectUrl = () => {
    let url = "/dashboard";
    if (redirectUrl) {
      url = redirectUrl;
      if (redirectSearch) {
        url = `${url}${redirectSearch}`;
      }
    }
    return url;
  };

  React.useEffect(() => {
    const update = [];
    for (let index = 0; index < steps.length; index++) {
      const step = steps[index];
      if (index === selectedIndex) {
        update.push({ ...step, status: StepperStatus.Current });
      } else if (index > selectedIndex) {
        update.push({ ...step, status: StepperStatus.Upcoming });
      } else {
        update.push({ ...step, status: StepperStatus.Complete });
      }
    }
    setSteps(update);
  }, [selectedIndex]);

  const saveProfileInformation = async (
    credential: UserCredential,
    photoUrl?: string
  ) => {
    // Save the user's profile information in their user document
    const { uid } = credential.user;
    try {
      await firebaseService?.setUserDoc(
        uid,
        Object.assign({
          uid,
          email,
          photoUrl: photoUrl ?? null,
          name: firstName,
          surname: lastName,
          cell: phoneNumber,
          del: "1",
          relationshipStatus: selectedRelationshipStatus.value,
          defaultAffordability: null,
          defaultBankDetails: null,
          defaultEmployerDetails: null,
          defaultAddress: null,
          sex: selectedSex.value,
          idNumber,
          language: selectedLanguage.name,
          languageCode: selectedLanguage.value,
          createdAt: "",
          updatedAt: "",
        })
      );
    } catch (error) {
      let message =
        "Something went wrong while authenticating your account, please try again later";
      console.log(JSON.stringify(error));
      if (error instanceof FirebaseError) {
        console.log(JSON.stringify(error));
        console.log(error.message);
        console.log(JSON.stringify(error.customData));
        if (error.code === "invalid-argument") {
          message = `Unable to sign up. ${error.message}`;
        }
      }
      makeToast(message, toast.TYPE.ERROR, false);
      toastId.current = null;
    }
  };

  // we need to keep a reference of the toastId to be able to update it
  const toastId = React.useRef<Id | null>(null);
  const onSearchForAccount = async (isValid: boolean) => {
    if (isValid) {
      // check if we already displayed a toast
      if (toastId.current === null) {
        toastId.current = toast("Searching database for account", {
          autoClose: false,
        });
      } else {
        toast.update(toastId.current, { autoClose: false });
      }
      try {
        const result = await firebaseService?.doVerifyIsNewUser(email);
        if (result && result.length > 0) {
          // An account was found for the user, redirect user to sign in
          const message = "An account was found for the user, please sign in";
          makeToast(message, toast.TYPE.INFO);
          navigate(`/auth/signin${location.search}`, {
            state: { email, skipSearch: true },
          });
        } else {
          // An account was not found for the user, continue with sign up
          const message =
            "An account was not found for the user, please continue";
          makeToast(message, toast.TYPE.SUCCESS);
          setSelectedIndex(selectedIndex + 1);
        }
        toastId.current = null;
      } catch (error) {
        const message = "Something went wrong while checking";
        makeToast(message, toast.TYPE.ERROR);
        toastId.current = null;
      }
    }
  };
  const onCreateAccount = async (isValid: boolean) => {
    if (isValid && firebaseService) {
      makeToast("Creating your account", toast.TYPE.INFO, false);
      try {
        const credential =
          await firebaseService.doCreateUserWithEmailAndPassword(
            firstName,
            lastName,
            email,
            password
          );
        if (credential) {
          // An account was created for the user
          const message =
            "An account was created successfully, saving your profile information";
          makeToast(message, toast.TYPE.INFO, false);
          await saveProfileInformation(credential);
          // Send user to the next step
          if (credential.user.emailVerified) {
            const successMsg =
              "Profile was saved successfully. Redirecting you to The Dashboard.";
            makeToast(successMsg, toast.TYPE.INFO);
            navigate(getRedirectUrl());
          } else {
            const successMsg =
              "Profile was saved successfully. Please verify your email address.";
            makeToast(successMsg, toast.TYPE.INFO);
            let url = `/auth/verify?type=init_email&email=${credential.user.email}`;
            if (redirectUrl) {
              url = `${url}&redirectUrl=${redirectUrl}`;
              if (redirectSearch) {
                // remove the initial question mark from the search string
                url = `${url}&redirectSearch${redirectSearch}`;
              }
            }
            navigate(url);
          }
        } else {
          // An account was not created for the user
          const message = "Unable to create an account, please try again";
          makeToast(message, toast.TYPE.ERROR, false);
        }
        toastId.current = null;
      } catch (error) {
        let message =
          "Something went wrong while authenticating your account, please try again later";
        if (error instanceof FirebaseError) {
          console.log(error.code);
          if (error.code === "auth/admin-restricted-operation") {
            message =
              "Unable to sign up. This feature has been disabled. Please contact support.";
          } else if (error.code === FirebaseAuthErrorCode.WrongPassword) {
            message =
              "Unable to sign up. Invalid email and password combination";
          } else if (error.code === FirebaseAuthErrorCode.UserNotFound) {
            message =
              "Unable to sign up. Invalid email and password combination";
          } else if (error.code === FirebaseAuthErrorCode.UserDisabled) {
            message =
              "Unable to sign up. Account disabled. Please contact support.";
          } else if (
            error.code === FirebaseAuthErrorCode.EmailExists ||
            error.code === "auth/email-already-in-use"
          ) {
            message =
              "Unable to sign up. The account already exists. Redirected to sign in page.";
            navigate(`/auth/signin${location.search}`, {
              state: { email, skipSearch: true },
            });
          }
        }
        makeToast(message, toast.TYPE.ERROR, false);
        toastId.current = null;
      }
    }
  };
  const onSignUpWithGoogle = async () => {
    if (firebaseService) {
      makeToast(
        "Authenticating your account with Google",
        toast.TYPE.INFO,
        false
      );
      try {
        const credential = await firebaseService.doSignInWithGoogle();
        if (credential) {
          // An account was authenticated for the user
          const message = "Account was authenticated successfully.";
          makeToast(message, toast.TYPE.SUCCESS);
          const providerUserInfo = credential.user.providerData.find(
            (data) => data.providerId === credential.providerId
          );
          const photoUrl = providerUserInfo?.photoURL ?? undefined;
          await saveProfileInformation(credential, photoUrl);
          navigate(getRedirectUrl());
        } else {
          // An account was not created for the user
          const message =
            "Unable to authenticate the account, please try again";
          makeToast(message, toast.TYPE.ERROR, false);
        }
        toastId.current = null;
      } catch (error) {
        let message =
          "Something went wrong while authenticating your account, please try again later";
        if (error instanceof FirebaseError) {
          if (error.code === FirebaseAuthErrorCode.WrongPassword) {
            message =
              "Unable to sign in. Invalid email and password combination";
          } else if (error.code === FirebaseAuthErrorCode.UserNotFound) {
            message =
              "Unable to sign in. Invalid email and password combination";
          } else if (error.code === FirebaseAuthErrorCode.UserDisabled) {
            message =
              "Unable to sign in. Account disabled. Please contact support.";
          }
        }
        makeToast(message, toast.TYPE.ERROR, false);
        toastId.current = null;
      }
    }
  };

  const makeToast = (
    message: string,
    type?: TypeOptions,
    autoClose?: number | false
  ) => {
    // check if we already displayed a toast
    if (toastId.current === null) {
      toastId.current = toast(message, {
        type,
        autoClose,
      });
    } else {
      toast.update(toastId.current, {
        render: message,
        type,
        autoClose,
      });
    }
  };

  const getCurrentTitle = () => {
    switch (selectedIndex) {
      case 0:
        return "Search for your account";
      case 1:
        return "Provide your profile information";
      case 2:
        return "Create your account";
      default:
        return "Sign up";
    }
  };

  const getCurrentDescription = () => {
    switch (selectedIndex) {
      case 0:
        return <p>{steps[0].description}</p>;
      case 1:
        return <p>{steps[1].description}</p>;
      case 2:
        return <p>{steps[2].description}</p>;
      default:
        return "Sign up";
    }
  };

  return (
    <Shell>
      <div className="bg-gray-100">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 items-start gap-12 md:grid-cols-4 lg:grid-cols-5">
            <div className="col-span-1 flex flex-1 flex-col justify-center pr-0 md:col-span-2 lg:col-span-3 lg:flex-none lg:pr-12 xl:pr-16">
              <div className="w-full max-w-3xl">
                <div>
                  <div className="mb-16 flex justify-between">
                    <p className="text-sm">
                      <span>Already a member?</span>{" "}
                      <Link
                        to={`/auth/signin${location.search}`}
                        state={{ email, skipSearch: email && email.length > 0 }}
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        Sign In
                      </Link>
                    </p>
                    <p
                      className={`text-sm transition-opacity duration-300 ${
                        selectedIndex === 2 || selectedIndex === 1
                          ? "opacity-1"
                          : "opacity-0"
                      }`}
                    >
                      <button
                        onClick={() => setSelectedIndex(selectedIndex - 1)}
                        disabled={!(selectedIndex === 2 || selectedIndex === 1)}
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        &larr;&nbsp;Go to previous
                      </button>
                    </p>
                  </div>
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                    {getCurrentTitle()}
                  </h2>
                  <p className="mt-4 text-base font-medium text-gray-600">
                    {getCurrentDescription()}
                  </p>
                </div>

                <div className="mt-8">
                  <div className="mt-6">
                    <Tab.Group selectedIndex={selectedIndex}>
                      <Tab.List className="hidden">
                        <Tab disabled={authUser !== null}>Tab 1</Tab>
                        <Tab disabled={authUser !== null}>Tab 2</Tab>
                        <Tab disabled={authUser !== null}>Tab 3</Tab>
                      </Tab.List>
                      <Tab.Panels>
                        <Tab.Panel tabIndex={0}>
                          <EmailForm
                            label="Search"
                            email={email}
                            onEmailChanged={setEmail}
                            onButtonClick={onSearchForAccount}
                          />
                        </Tab.Panel>
                        <Tab.Panel tabIndex={1}>
                          <CreateProfileForm
                            label="Next"
                            firstName={firstName}
                            lastName={lastName}
                            phoneNumber={phoneNumber}
                            phoneNumberErrorMessage={phoneNumberErrorMessage}
                            idNumber={idNumber}
                            selectedLanguage={selectedLanguage}
                            selectedSex={selectedSex}
                            selectedRelationshipStatus={
                              selectedRelationshipStatus
                            }
                            onSelectedLanguageChanged={setSelectedLanguage}
                            onSelectedSexChanged={setSelectedSex}
                            onSelectedRelationshipStatusChanged={
                              setSelectedRelationshipStatus
                            }
                            onFirstNameChanged={setFirstName}
                            onLastNameChanged={setLastName}
                            onPhoneNumberChanged={(value, errorMessage) => {
                              setPhoneNumber(value);
                              setPhoneNumberErrorMessage(errorMessage);
                            }}
                            onIdNumberChanged={setIdNumber}
                            onButtonClick={(isValid) =>
                              isValid && setSelectedIndex(2)
                            }
                          />
                        </Tab.Panel>
                        <Tab.Panel tabIndex={2}>
                          <div>
                            <div>
                              <p className="text-sm font-medium text-gray-700">
                                Sign up with
                              </p>

                              <div className="mt-1 grid grid-cols-1 gap-3">
                                <div>
                                  <button
                                    onClick={onSignUpWithGoogle}
                                    className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                                  >
                                    <span className="sr-only">
                                      Sign up with Google
                                    </span>
                                    <svg
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      viewBox="0 0 48 48"
                                    >
                                      <defs>
                                        <path
                                          id="a"
                                          d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
                                        />
                                      </defs>
                                      <clipPath id="b">
                                        <use
                                          xlinkHref="#a"
                                          overflow="visible"
                                        />
                                      </clipPath>
                                      <path
                                        clipPath="url(#b)"
                                        fill="#FBBC05"
                                        d="M0 37V11l17 13z"
                                      />
                                      <path
                                        clipPath="url(#b)"
                                        fill="#EA4335"
                                        d="M0 11l17 13 7-6.1L48 14V0H0z"
                                      />
                                      <path
                                        clipPath="url(#b)"
                                        fill="#34A853"
                                        d="M0 37l30-23 7.9 1L48 0v48H0z"
                                      />
                                      <path
                                        clipPath="url(#b)"
                                        fill="#4285F4"
                                        d="M48 48L17 24l-4-3 35-10z"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className="relative mt-6">
                              <div
                                className="absolute inset-0 flex items-center"
                                aria-hidden="true"
                              >
                                <div className="w-full border-t border-gray-300" />
                              </div>
                              <div className="relative flex justify-center text-sm">
                                <span className="bg-gray-100 px-2 text-gray-500">
                                  Or continue with
                                </span>
                              </div>
                            </div>
                          </div>
                          <CreateAccountForm
                            label="Submit"
                            email={email}
                            password={password}
                            confirmPassword={confirmPassword}
                            onEmailChanged={setEmail}
                            onPasswordChanged={setPassword}
                            onConfirmPasswordChanged={setConfirmPassword}
                            onButtonClick={onCreateAccount}
                          />
                        </Tab.Panel>
                      </Tab.Panels>
                    </Tab.Group>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative col-span-1 block h-full w-full flex-1 overflow-hidden rounded-3xl md:col-span-2 lg:col-span-2">
              <img
                className="absolute inset-0 top-0 bottom-0 left-0 right-0 h-full w-full rounded-3xl object-cover shadow-sm"
                src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                alt=""
              />
              <div
                className="absolute inset-0 rounded-md bg-indigo-800 mix-blend-multiply"
                aria-hidden="true"
              ></div>
              <p className="relative top-0 bottom-0 left-0 right-0 h-full w-full  py-12 px-4 md:px-6 lg:px-8">
                <p className="text-3xl font-bold leading-[40px] text-indigo-100">
                  Access the Dashboard
                </p>
                <p className="mt-4 text-xl font-light text-indigo-200">
                  Discover a new and easy way of managing your finances.
                </p>
                <div className="mt-8 rounded-xl bg-slate-200/80 px-4 py-8 ring-indigo-600 md:py-10 md:px-6">
                  <Stepper onStepClick={() => null} steps={steps} />
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Shell>
  );
};

export default SignUpPage;

export const Head: HeadFC = () => (
  <CustomHead title="Sign Up" description={pageDescription} />
);
