import * as React from "react";
import ConfirmPasswordField from "../fields/ConfirmPasswordField";
import EmailField from "../fields/EmailField";
import PasswordField from "../fields/PasswordField";

export interface CreateAccountFormProps {
  label: string;
  email: string;
  password: string;
  confirmPassword: string;
  type?: "submit" | "button";
  onButtonClick: (isValid: boolean) => void;
  onEmailChanged: (email: string) => void;
  onPasswordChanged: (password: string) => void;
  onConfirmPasswordChanged: (confirmPassword: string) => void;
}

const CreateAccountForm = (props: CreateAccountFormProps) => {
  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isFormValid = event.currentTarget.checkValidity();
    props.onButtonClick(isFormValid);
  };

  return (
    <form onSubmit={submitForm} className="space-y-6">
      <EmailField email={props.email} onChange={props.onEmailChanged} />
      <PasswordField
        password={props.password}
        onChange={props.onPasswordChanged}
      />
      <ConfirmPasswordField
        confirmPassword={props.confirmPassword}
        password={props.password}
        onChange={props.onConfirmPasswordChanged}
      />
      <button
        type={props.type ?? "submit"}
        className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        {props.label}
      </button>
    </form>
  );
};

export default CreateAccountForm;
