import * as React from "react";

export interface ConfirmPasswordFieldProps {
  confirmPassword: string;
  password: string;
  onChange: (password: string) => void;
}

const ConfirmPasswordField = ({
  confirmPassword,
  password,
  onChange,
}: ConfirmPasswordFieldProps) => {
  return (
    <div className="space-y-1">
      <label
        htmlFor="confirm-password"
        className="block text-sm font-medium text-gray-700"
      >
        Confirm Password
      </label>
      <div className="mt-1">
        <input
          id="confirm-password"
          name="confirm-password"
          type="password"
          pattern={password}
          value={confirmPassword}
          onChange={(e) => onChange(e.target.value)}
          required
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        />
      </div>
    </div>
  );
};

export default ConfirmPasswordField;
