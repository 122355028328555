import * as React from "react";
import EmailField from "../fields/EmailField";

export interface EmailFormProps {
  label: string;
  email: string;
  type?: "submit" | "button";
  onButtonClick: (isValid: boolean) => void;
  onEmailChanged: (email: string) => void;
}

const EmailForm = (props: EmailFormProps) => {
  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isFormValid = event.currentTarget.checkValidity();
    props.onButtonClick(isFormValid);
  };

  return (
    <form onSubmit={submitForm} className="space-y-6">
      <EmailField email={props.email} onChange={props.onEmailChanged} />
      <button
        type="submit"
        className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        {props.label}
      </button>
    </form>
  );
};

export default EmailForm;
